import React from 'react';

const EDocsLogoMini = () => {
	return (
		<img
			src={`${process.env.PUBLIC_URL}/edocuments-fav.png`}
			alt="eDocuments Logo"
			style={{ width: 40, height: 40 }}
		/>
	);
};

export default EDocsLogoMini;