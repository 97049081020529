import React from 'react';
import AccordionData from './AccordionData';
import AttributesList from './AttributesList';

const AssetSpares = ({ data }) => {
	if (!data) return null;
	return <AccordionData title="Spares" data={data}>
		{data.map(spare => <AccordionData key={spare} title={spare.Description} data={spare}>
			<AttributesList attributes={spare}></AttributesList>
		</AccordionData>
		)}
	</AccordionData>;
};

export default AssetSpares;
