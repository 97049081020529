import React, { useState } from 'react';
import {
	Card,
	CardContent,
	Grid,
	Stack,
	Typography,
} from '@mui/material';
import { getFileLink } from '../../../api/Api';
import { LoadingButton } from '../../ui'
import AccordionData from './AccordionData';
import AssetAttributes from './AssetAttributes';
import AttributesList from './AttributesList';

const FileCard = ({ item, linkId, authToken }) => {
	const [isLinkLoading, setIsLinkLoading] = useState(false);

	const loadFileLink = async (placeholderId) => {
		setIsLinkLoading(true);
		const fileLinkData = await getFileLink(linkId, placeholderId, authToken);
		console.log("FileLink data:", fileLinkData);
		if (fileLinkData.status === "success") {
			let link = fileLinkData.data.result.data;
			window.open(link, '_blank');
		}

		setIsLinkLoading(false);
	}

	const shouldRenderView = item.metaData?.fileId != null && item.metaData.fileId !== '';
	const coreFileAttrs = {
		'Type': item.metaData.typeDescription
	}
	return (
		<Card style={{ marginBottom: 5 }}>
			<CardContent sx={{ backgroundColor: 'grey.300' }}>
				<Stack
					direction="column"
					spacing={1}
					sx={{ height: '100%' }}
				>
					<Typography variant="body1" style={{ fontWeight: 'bold' }}>
						{item.metaData.description}
					</Typography>
					<AttributesList attributes={coreFileAttrs}></AttributesList>
					<AssetAttributes attributes={item.attributes} />
					{shouldRenderView &&
						<div style={{ marginTop: 'auto', paddingTop: 10 }}>
							<LoadingButton
								variant="contained"
								color="primary"
								size="small"
								style={{ float: 'right' }}
								onClick={() => loadFileLink(item.metaData.placeholderId)}
								isLoading={isLinkLoading}
							>
								View
							</LoadingButton>
						</div>
					}
				</Stack>
			</CardContent>
		</Card>
	);
};

const AssetFiles = ({ title, files, linkId, authToken }) => {
	if (!files) return null;

	const withFileData = files.filter(item => item.file !== null);
	return (
		<AccordionData title="Files" data={files}>
			<Grid container direction="row" spacing={2}>
				{withFileData.length ? withFileData.map((item) => (
					<Grid item key={item.metaData} xs={12} sm={6} md={4} lg={3}>
						<FileCard item={item} linkId={linkId} authToken={authToken} />
					</Grid>
				)) : <Typography variant="caption" margin="5px 12px">(No files to display)</Typography>}
			</Grid>
		</AccordionData>
	);
};

export default AssetFiles;
