import React from 'react';
import AccordionData from './AccordionData';
import AttributesList from './AttributesList';

const AssetAttributes = ({ attributes, customAttributes }) => {
	if (!attributes) return null;
	return (
		<AccordionData title="Attributes" data={attributes}>
			<AttributesList attributes={attributes} customAttributes={customAttributes}></AttributesList>
		</AccordionData>
	);
};

export default AssetAttributes;
