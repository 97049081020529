import React from 'react';
import { Box, Typography } from '@mui/material';
import AttributeName from './AttributeName';
import AccordionData from './AccordionData';

const AttributesList = ({ attributes, customAttributes }) => {
	if (!attributes) return null;
	return <Box display="flex" flexDirection="column" gap="10px">
		{Object.entries(attributes).map(([key, value]) => (
			<Box display="flex" flexDirection="column" gap="4px" key={key}>
				<Typography variant="attrName" component="div"><AttributeName name={key}></AttributeName></Typography>
				<Typography variant="attrValue" component="div">{value}</Typography>
			</Box>
		))}
		{customAttributes?.length ? <AccordionData title="Custom attributes">
			{customAttributes.map(attr => (
				<Box display="flex" flexDirection="column" gap="4px" key={attr.Name}>
					<Typography variant="attrName" component="div">{attr.Name}</Typography>
					<Typography variant="attrValue" component="div">{attr.Value} {attr.Unit}</Typography>
					<Typography variant="caption" component="div">{attr.Notes}</Typography>
				</Box>
			))}
		</AccordionData> : null}
	</Box>;
};

export default AttributesList;
