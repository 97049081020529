import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Avatar, Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const AccordionData = ({ title, data, children }) => {

	const parameterCount = data ? Object.keys(data).length : -1;

	return (
		<Accordion>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<Box display="flex" alignItems="center">
					<Typography variant="body1" sx={{ marginRight: 1 }}>
						{title}
					</Typography>
					{parameterCount < 0 ? null :
						<Avatar sx={{ width: 24, height: 24 }}>
							<Typography variant="caption" sx={{color:"#FFFFFF"}}>
								{parameterCount}
							</Typography>
						</Avatar>
					}
				</Box>
			</AccordionSummary>
			<AccordionDetails>
				{children}
			</AccordionDetails>
		</Accordion>
	);
};

export default AccordionData;
