import React from 'react';
import { Box, } from '@mui/material';
import AttributesList from './AttributesList';

const AssetDataMainHeader = ({ assetHeaderData }) => {
	return (
		<Box>
			<AttributesList attributes={assetHeaderData}></AttributesList>
		</Box>
	)
};

export default AssetDataMainHeader;
