import React, { useContext } from 'react';
import AliasContext from './AliasContext';

const AttributeName = ({ name }) => {
	const aliases = useContext(AliasContext);
	if (!name) return null;
	return <>{aliases[name] || name}</>;
};

export default AttributeName;
