// src/Login.js
import React, { useEffect, useState } from 'react';
import { Alert, Container } from '@mui/material';

const Login = ({ authToken, setAuthToken }) => {
	const appId = process.env.REACT_APP_APP_ID;

	const [showAlert, setShowAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState('');
	const [alertSeverity, setAlertSeverity] = useState('warning');

	useEffect(() => {
		fetch('https://edocsapi.azurewebsites.net/AuthMFA/api/Request/Create', {
			method: 'POST',
			body: JSON.stringify({ caller: 'AssetViewer' }),
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Digest username="' + appId + '" realm="_root" password=""',
			}
		}).then(async response => {
			response = await response.json();
			const authReq = response.result.authRequest;
			if (!authReq) throw new Error('Invalid response - no auth request present in response');
			const popupProperties = ['popup', 'width=400', 'height=450', 'top=' + window.innerHeight / 3, 'left=' + window.innerWidth / 3];
			const authWin = window.open('https://portal.dexio.com/#/request/' + authReq.id, 'EdocsAuthRequest', popupProperties.join(','));

			if (!authWin) {
				setShowAlert(true);
				setAlertSeverity('error');
				setAlertMessage('Failed opening a new auth window, please check for a blocked popup');
				return;
			}

			function checkForClose() {
				if (!authWin.closed) return setTimeout(checkForClose, 500);
				setTimeout(() => {
					if (!authToken) {
						setShowAlert(true);
						setAlertSeverity('error');
						setAlertMessage('Auth window closed before authorisation could complete. Please refresh and try again.');
					}
				}, 750)
			}

			window.focus();

			setTimeout(() => {
				if (authWin && !authWin.closed) authWin.focus();
				checkForClose();
			}, 750);

			setShowAlert(true);
			setAlertSeverity('info');
			setAlertMessage('Awaiting authorisation in new auth window...');

			window.onmessage = function(event) {
				if (!event.data || event.data.message !== 'AuthRequest.Complete') return;
				const authReq = event.data.authRequest;
				console.log(authReq.authToken, authReq.dateExpires);
				setAuthToken(authReq.authToken);
				authWin.close();
			};
		}).catch(error => {
			console.error('Failed to create auth request', error);
			setShowAlert(true);
			setAlertSeverity('error');
			setAlertMessage('Authorisation process failed, please refresh to try again.');
		})
	}, [appId, setAuthToken])

	return (
		<Container>
			{showAlert && <Alert severity={alertSeverity}>{alertMessage}</Alert>}
		</Container>
	);
};

export default Login;