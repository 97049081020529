import React from 'react';
import { Box, Typography } from '@mui/material';
import { EDocsLogoMini } from '../../ui';

const AssetViewerHeader = ({ assetData }) => {
	const mb6Params = new URLSearchParams();
	mb6Params.append('S', assetData.metaData.siteId);
	mb6Params.append('G', assetData.metaData.projectId);
	mb6Params.append('W', assetData.metaData.documentId);
	return (
		<Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
			<a target={'MB6_' + assetData.metaData.siteId} href={'https://edocumentsbuild.com/document-editor.htm?' + mb6Params.toString()}>
				<EDocsLogoMini />
			</a>
			<Typography variant="caption" sx={{ marginLeft: 1 }}>
				Single Component Viewer
			</Typography>
		</Box>
	);
};

export default AssetViewerHeader;