import React, { useContext } from 'react';
import { Typography } from '@mui/material';
import AccordionData from './AccordionData';
import AttributesList from './AttributesList';
import AliasContext from './AliasContext';
import { getAliasHelper } from '../../../utils';

const AssetHealthSafety = ({ data }) => {
	const aliases = useContext(AliasContext);
	if (!data) return null;
	const alias = getAliasHelper(aliases);
	return <AccordionData title={"H&S"} data={data}>
		<HSAccordion label={alias('COSHH')} data={data.coshh}></HSAccordion>
		<HSAccordion label={alias('Residual Risk')} data={data.residualRisk}></HSAccordion>
		<HSAccordion label={alias('Disposal')} data={data.disposal}></HSAccordion>
	</AccordionData>;
};

function HSAccordion({ label, data }) {
	data = data || [];
	const attrs = {};
	data.forEach((val, idx) => {
		attrs[label + ' ' + (idx + 1)] = val;
	});
	return <AccordionData title={label} data={attrs}>
		{!data?.length ? <Typography variant="caption">(No data to display)</Typography> : (
			data.length === 1 ? <Typography variant="attrValue">{data[0]}</Typography> : <AttributesList attributes={attrs}></AttributesList>
		)}
	</AccordionData>;
}

export default AssetHealthSafety;
