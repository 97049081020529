import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme, Container } from '@mui/material';
import {
	AssetViewerPage,
	ErrorPage
} from './components/pages';

const theme = createTheme({
	typography: {
		// PAGE NAME:
		caption: {
			fontFamily: 'Roboto',
			fontSize: '12px',
			fontWeight: 400,
			lineHeight: '19.92px',
			letterSpacing: '0.4px',
			textAlign: 'right',
			color: '#00000061'
		},
		// CARD HEADERS:
		body1: {
			fontFamily: 'Roboto',
			fontSize: '16px',
			fontWeight: 400,
			lineHeight: '24px',
			letterSpacing: '0.15px',
			textAlign: 'left',
			color: "#000000DE",
		},
		// Attribute name:
		attrName: {
			fontFamily: 'Roboto',
			fontSize: '14px',
			fontWeight: 'normal',
			lineHeight: '1.2',
			textAlign: 'left',
			color: '#0006',
		},
		// Attribute value:
		attrValue: {
			fontFamily: 'Roboto',
			fontSize: '14px',
			fontWeight: 'normal',
			lineHeight: '1.2',
			textAlign: 'left',
			color: '#000F',
		},
		// ASSET HEADER TEXT:
		body2: {
			fontFamily: 'Roboto',
			fontSize: '14px',
			fontWeight: 400,
			lineHeight: '20.02px',
			letterSpacing: '0.17px',
			textAlign: 'left',
		},
		h4: {
			fontFamily: 'Roboto',
			fontSize: '24px',
			fontWeight: 400,
			lineHeight: '42px',
			letterSpacing: '0.25px',
			textAlign: 'left',
		},
		h5: {
			fontFamily: 'Roboto',
			fontSize: '18px',
			fontWeight: 400,
			lineHeight: '32px',
			letterSpacing: '0px',
			textAlign: 'left',
		},
		h6: {
			fontFamily: 'Roboto',
			fontSize: '14px',
			fontWeight: 500,
			lineHeight: '32px',
			letterSpacing: '0.15px', // Rounded from 0.15000000596046448px
			textAlign: 'left',
		},
	},
	palette: {
		info: {
			main: '#03A9F4',
			contrastText: '#ffffff'
		},
		primary: {
			main: '#673AB7',
		},
		background: {
			default: "#ffffff",
			paper: 'white'
		}
	}
});

const App = () => {
	const [linkId, setLinkId] = useState(null);

	useEffect(() => {
		const extractLinkIdFromURL = () => {
			const url = window.location.href;
			const parts = url.split('?');
			if (parts.length > 1) {
				return parts[1];
			}
			return null;
		};

		const linkId = extractLinkIdFromURL();
		setLinkId(linkId);
	}, []);

	if (!linkId) {
		return <ErrorPage error="LinkId is required" />
	}

	return (
		<ThemeProvider theme={theme}>
			<Container
				maxWidth="lg"
				sx={{
					backgroundColor: theme.palette.background.paper,
					padding: (theme) => `${theme.spacing(2)} ${theme.spacing(4)}`
				}}
			>
				<AssetViewerPage linkId={linkId} />
			</Container>
		</ThemeProvider>
	);
};

export default App;
