
const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;


const fetchJsonData = async (linkId, password) => {
	const myHeaders = new Headers();
	myHeaders.append("X-Version", "1.0");
	myHeaders.append("Authorization", `Digest username="hxxijnjxfbb" realm="_root_" password="${password}"`);
	myHeaders.append("linkId", linkId);

	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		redirect: "follow"
	};

	try {
		const response = await fetch(`${BASE_URL}workspace/SharedLink/GetComponentData`, requestOptions);
		if (!response.ok) {
			const text = await response.text();
			console.log("RESPONSE:", response);
			console.log("RESPONSE TEXT:", text);
			if (response.status === 401 || (text && JSON.parse(text).Error && JSON.parse(text).Error.Code === 'FW403')) {
				return { status: 'invalid_security', message: 'Insufficient security' };
			} else {
				throw new Error('Error fetching data');
			}
		}
		const data = await response.json();
		return { status: 'success', data };
	} catch (error) {
		return { status: 'error', message: error.message };
	}
};

const getFileLink = async (linkId, placeholderId, password) => {
	const myHeaders = new Headers();
	myHeaders.append("X-Version", "1.0");
	myHeaders.append("Authorization", `Digest username="hxxijnjxfbb" realm="_root_" password="${password}"`);
	myHeaders.append("linkId", linkId);

	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		redirect: "follow"
	};

	try {
		const response = await fetch(`${BASE_URL}workspace/SharedLink/GetFileLink?placeholderId=${placeholderId}`, requestOptions);
		if (!response.ok) {
			const text = await response.text();
			console.log("RESPONSE:", response);
			console.log("RESPONSE TEXT:", text);
			if (response.status === 401 || (text && JSON.parse(text).Error && JSON.parse(text).Error.Code === 'FW403')) {
				return { status: 'invalid_security', message: 'Insufficient security' };
			} else {
				throw new Error('Error fetching data');
			}
		}
		const data = await response.json();
		return { status: 'success', data };
	} catch (error) {
		return { status: 'error', message: error.message };
	}
}

const exchangeGuidForToken = async (authGuid) => {
	const myHeaders = new Headers();
	myHeaders.append("X-Version", "1.0");
	myHeaders.append("authToken", authGuid);
	myHeaders.append("Authorization", `Digest username="hxxijnjxfbb" realm="_root_" password=""`);


	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		redirect: "follow"
	};

	try {
		const response = await fetch(`${BASE_URL}workspace/Auth/GetAuthToken`, requestOptions);
		const data = await response.json();
		if (response.ok) {
			//console.log("response", data)
			return { status: 'success', token: data.token };
		} else {
			return { status: 'error', message: "bad response code." };
		}
	} catch (error) {
		return { status: 'error', message: "E" };
	}
};

export { fetchJsonData, exchangeGuidForToken, getFileLink };