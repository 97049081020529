import React, { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { fetchJsonData } from '../../../api/Api';
import {
	Login,
	LoadingPage,
	ErrorPage
} from '..';
import AssetAttributes from './AssetAttributes';
import AssetFiles from './AssetFiles';
import AssetHealthSafety from './AssetHealthSafety';
import AssetViewerHeader from './AssetViewerHeader';
import AssetDataMainHeader from './AssetDataMainHeader';
import AliasContext from './AliasContext';
import AssetSpares from './AssetSpares';

const AssetViewerPage = ({ linkId }) => {
	const [responseState, setResponseState] = useState({ status: 'loading' });
	const [authToken, setAuthToken] = useState("");
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			const result = await fetchJsonData(linkId, authToken);
			setResponseState(result);
		};

		if (linkId) {
			fetchData();
		}
	}, [authToken, linkId]);

	if (responseState.status === 'loading') {
		return <LoadingPage />
	}

	if (responseState.status === 'error') {
		return <ErrorPage error={responseState.message} />
	}

	if (responseState.status === 'invalid_security') {
		return <Login authToken={authToken} setAuthToken={setAuthToken} setResponseState={setResponseState} linkId={linkId} />;
	}

	let assetData = responseState.data.result?.data;

	const aliases = assetData.attributeAliases || {};

	return <AliasContext.Provider value={aliases}>
		<Stack spacing={2}>
			<AssetViewerHeader assetData={assetData} />
			<AssetDataMainHeader assetHeaderData={assetData.keyData} />
			<Box>
				<AssetAttributes attributes={assetData.attributes} customAttributes={assetData.customAttributes} />
				<AssetFiles files={assetData.fileDisplayData} linkId={linkId} authToken={authToken} />
				<AssetHealthSafety data={assetData.healthAndSafety} />
				<AssetSpares data={assetData.spares} />
			</Box>
		</Stack>
	</AliasContext.Provider>;
};

export default AssetViewerPage;
