import React from 'react';
import { Button, CircularProgress } from '@mui/material';

const LoadingButton = ({ isLoading, disabled, children, ...props }) => {
	return (
		<Button
			disabled={isLoading || disabled}
			{...props}
		>
			{isLoading ? <CircularProgress size={24} /> : children}
		</Button>
	);
};

export default LoadingButton;